.card {
  text-decoration: none;
  color: var(--color-black);
  @extend %typo-body1;
  position: relative;
  display: block;

  &__container {
    height: fit-content;
  }

  &__description {
    margin-top: var(--spacing-8);
  }

  &__tag {
    position: absolute;
    top: var(--spacing-16);
    left: var(--spacing-16);
    padding: 4px 6px;
    border-radius: 2px;
    text-transform: uppercase;
    background: var(--color-primary);
    color: var(--color-primary-contrast);
    @extend %typo-caption-product-card;

    &.black-tag {
      background: var(--color-dark);
    }
  }

  &__image {
    background: var(--color-tertiary);
    aspect-ratio: 3 / 4;
    border-radius: 4px;
    position: relative;

    & img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &.has_alt {
      & .product__image_alt {
        display: none;
      }
      @media (--small) {
        &:hover {
          & .product__image {
            display: none;
          }

          & .product__image_alt {
            display: block;
          }
        }
      }
    }
  }

  &__swatches {
    margin-top: var(--spacing-20);
    display: flex;
    gap: var(--spacing-8);
  }

  &__swatches_more {
    @extend %typo-caption-product-card;
    color: var(--color-text-secondary);
    text-transform: uppercase;
  }
}

.swatch {
  width: 8px;
  height: 10px;
  border-radius: 4px;
  background: #ddd;

  &.border {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
  }
}

.product__meta {
  @extend %typo-body2;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  gap: 3px;

  & span {
    display: block;
  }
}

.product__image {
  object-fit: contain;
}

.related {
  & .card {
    display: flex;
    gap: var(--spacing-16);
    margin-bottom: var(--spacing-16);

    & > * {
      width: 50%;
    }

    &__description {
      margin: 0;
    }
  }
}

.tags {
  display: flex;
  gap: var(--spacing-8);
  position: absolute;
  top: var(--spacing-8);
  left: var(--spacing-8);
  flex-wrap: wrap;
}

.tag {
  border-radius: 2px;
  padding: 4px 6px;
  text-transform: uppercase;
  display: block;

  @extend %typo-caption-product-card;

  &.tag__regular {
    background: var(--color-tertiary-light, white);
    color: var(--color-text);
  }

  &.tag__green {
    background: var(--color-primary);
    color: var(--color-light);
  }

  &.tag__dark {
    background: var(--color-dark);
    color: var(--color-light);
  }
}
