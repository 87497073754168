@media (prefers-reduced-motion: no-preference) {
  :root {
    /* scale */
    --scale: 1.05;
    --scale-large: 1.2;
    --scale-transition: all ease 0.15s;
    --scroll-transition: 800ms;

    /* underline transition */
    --underline-transition: width 0.15s ease;

    /* fade */
    --fade-transition: opacity 0.15s ease;
  }

  /* globals, overwrite to turn off */
  [data-blok][data-animated="fadeY"] {
    transform: translateY(40px);
    transition: all var(--scroll-transition);
    opacity: 0;

    @media (--large) {
      transform: translateY(1.5vw);
    }

    &[data-view="true"] {
      opacity: 1;
      transform: translateY(0);
    }

    @media (prefers-reduced-motion) {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ---  */

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translateX(200%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @media (prefers-reduced-motion) {
    * {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
}

.underline-hover {
  @extend %underline-hover;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin-left: var(--page-inset);
}
@keyframes l5 {
  0% {
    box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
    background: var(--color-primary);
  }
  33% {
    box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
    background: var(--color-primary-light);
  }
  66% {
    box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
    background: var(--color-primary-light);
  }
  100% {
    box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
    background: var(--color-primary);
  }
}
