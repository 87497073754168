.root {
  background: var(--color-primary);
  color: var(--color-white);
  margin-top: calc(var(--section-spacing) * 2);
  padding-bottom: 40px; /* make room for widget */

  &.noMargin {
    margin-top: 0;
  }
}

.newsletter {
  padding: var(--section-spacing) 0;
  background: #f1eee9;
  color: var(--color-black);

  &__inner {
    width: 100%;

    @media (--small) {
      width: 50%;
      min-width: 400px;
    }
  }

  & span {
    color: rgba(14, 14, 14, 0.72);
  }

  &__input {
    width: 100%;
    display: block;
    background: transparent;
    border: 0;
    padding: 12px 0;
    border-bottom: 1px solid rgba(14, 14, 14, 0.16);
    margin: 4px 0 12px;
    min-height: 62px;
  }

  &__checkbox {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-16);
    @extend %typo-body3;

    & a {
      text-decoration: underline;
    }
  }
}

.checkboxTrigger {
  & input {
    position: absolute;
    opacity: 0;
  }
  & span {
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid var(--color-dark);
    position: relative;
    margin-top: 4px;
  }

  & input:checked + span {
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background: var(--color-primary);
      transform: translateY(-50%) translateX(-50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}

.footer {
  max-width: var(--page-inset-width);
  margin-inline: auto;
  padding: var(--section-spacing) 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-16);

  @media (--small) {
    grid-template-columns: 2fr 2fr 2fr 5fr;
    gap: 0;
  }
}

.location {
  @media (--small) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}

.col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--section-spacing);

  @media (--small) {
    grid-template-columns: 1fr 1fr 1fr auto;
    margin-bottom: 0;
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--base);

  @extend %typo-body2;

  & a {
    color: var(--color-white);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.copy {
  --svg-color: var(--color-white);
  padding-bottom: var(--spacing-20);
  padding-top: var(--spacing-96);
  align-items: center;

  @media (--small) {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
  }
}

.paymentRatingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--small) {
    flex-direction: row;
    gap: var(--spacing-32);
  }
}

.rating {
  @extend %typo-caption2;
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--color-white);
  height: 30px;
  text-decoration: none;
  justify-content: center;

  @media (--small) {
    justify-content: none;
  }
}

.copylinks {
  flex-direction: columns;
  gap: var(--spacing-24);
  @extend %typo-body3;
  align-items: center;
  margin-top: var(--spacing-64);

  & > div {
    gap: var(--spacing-12);
    display: flex;
  }

  @media (--large) {
    flex-direction: row;
    align-items: unset;
    margin: 0;
  }
}

.preamble {
  margin-bottom: var(--spacing-24);
  @extend %typo-body3;
}

.accordionWrapper {
  border-bottom: 1px solid var(--color-divider-contrast);
}

.trigger {
  border-top: 1px solid var(--color-divider-contrast) !important;
  border-bottom: none !important;
}

.accordion {
  &__content {
    @extend %typo-body2;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-16) 0;

    a {
      padding: var(--spacing-12) 0;
    }
  }
}

.divider {
  height: var(--spacing-16);
}

.message {
  margin-top: var(--spacing-16);
  color: var(--color-error);

  &.success {
    color: var(--color-primary);
    @extend %typo-body2;
    margin-bottom: var(--spacing-48);

    & h2 {
      @extend %typo-headline2;
      font-size: 22px;
      margin-bottom: var(--spacing-8);
    }
  }
}

.inputWrap {
  position: relative;
}

.submit {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  & button {
    width: 40px;
    height: 40px;

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  & button,
  & button:hover {
    --svg-color: rgba(14, 14, 14, 0.12) !important;
    border-color: rgba(14, 14, 14, 0.12) !important;
    background: transparent !important;
  }
}

.form:valid {
  .submit {
    position: absolute;
    top: 50%;
    right: var(--spacing-16);
    transform: translateY(-50%);

    & button,
    & button:hover {
      --svg-color: var(--color-dark) !important;
      border-color: var(--color-dark) !important;
      background: transparent !important;
    }
  }
}
