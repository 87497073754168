.input {
  border: 0;
  border-bottom: 1px solid var(--color-divider);
  width: 100%;
  height: 48px;
  @extend %typo-body1;
  color: var(--color-dark);

  &:hover,
  &:active,
  &:focus {
    border-color: var(--color-dark);
  }

  &:focus-visible {
    outline: var(--color-dark);
  }
}

.inputWrap {
  margin: var(--spacing-24) 0;
}

.message {
  color: var(--color-error);
  display: none;
  @extend %typo-body2;
}

.invalid + .message {
  display: block;
}
