.container {
  display: flex;
  flex-direction: column-reverse;

  @media (--small) {
    flex-direction: row;
  }
}




.checkout {
  &__form {
    width: 100%;
    border-right: 1px solid var(--color-divider);

    & h2 {
      @extend %typo-headline2;
      font-size: 22px; /* inconsistancy in design file */
    }
  }
  &__cart {
    width: 100%;
    padding: var(--section-spacing) 0;
    display: none;

    @media (--small) {
      display: block;
    }
  }
  &__cartwrap {
    position: sticky;
    top: var(--section-spacing);

    & > div {
      &:first-child {
        border-bottom: 1px solid var(--color-divider);
      }

      & > div {
        max-width: 600px;
        border: 0;
      }
    }
  }

  &__loginText {
    margin-top: var(--spacing-8);
    & p,
    a {
      color: var(--color-tertiary-text) !important;
      @extend %typo-body2;

      & a {
        text-decoration: underline;
      }
    }
  }

  &__accordion {
    width: 100%;
  }

  &__step {
    border-bottom: 1px solid var(--color-divider);
    padding: var(--section-spacing) var(--margin-spacing);
    padding-left: var(--smart-page-inset);
    position: relative;

    & > .shippingOption:first-of-type {
      margin-top: var(--spacing-24);
    }

    &:last-child {
      padding-bottom: calc(var(--section-spacing) * 2);
    }
  }

  &__header {
    padding: var(--spacing-24) 0;
    background: var(--color-tertiary);

    & h1 {
      color: var(--color-primary);
      margin-top: var(--spacing-8);
    }
  }
  &__back {
    --svg-color: var(--color-primary);
    color: var(--color-primary);
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: var(--font-size-2xs);

    & svg {
      width: 16px;
      height: 16px;
    }
  }
}

.shippingOption {
  display: flex;
  border-top: 1px solid var(--color-divider);
  padding: var(--spacing-16) 0;
  gap: var(--spacing-16);
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  transition: opacity 500ms;

  @extend %typo-body2;

  & a {
    text-decoration: underline;
    color: var(--color-tertiary-text);
  }

  .loading & {
    opacity: 0.3;
    pointer-events: none;
  }

  &:last-child {
    border-bottom: 1px solid var(--color-divider);
  }

  &__wrap {
    display: flex;
    gap: var(--spacing-16);
    align-items: center;

    & p {
      padding-top: 3px;
    }
  }

  &__note {
    color: var(--color-tertiary-text);
  }

  & input {
    display: none;
  }

  &__input {
    min-width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 1px solid var(--color-dark);
    position: relative;

    &.checked {
      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: var(--color-dark);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
}

.addAddress2Container {
  display: block;
  text-align: right;
  position: relative;

  & button {
    position: absolute;
    right: 0;
  }
}

.label {
  @extend %typo-label;
  color: var(--color-secondary-text);
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  padding-bottom: var(--spacing-8);
}

.checkout__inner {
  position: relative;
  min-height: 30rem;

  &:has([data-loading="true"]) {
    display: grid;
    place-items: center;
  }

  & .validationBlocker {
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      background: var(--color-white);
      padding: var(--spacing-8);
      margin-bottom: var(--spacing-48);
    }
  }
}

.error {
  color: var(--color-error);
  margin-bottom: var(--spacing-16);
  @extend %typo-body2;
}

.separate_billing {
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.account {
  @extend %typo-body2;

  & p {
    max-width: 600px;
    margin: var(--spacing-24) 0 0;
  }

  & button,
  & button:hover {
    border-bottom: 1px solid var(--color-dark) !important;
  }

  &__options {
    margin: var(--spacing-24) 0 0;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);

    & button,
    & a {
      max-width: 600px;
    }
  }
}
