.container {
  border-bottom: 1px solid var(--color-divider);
  padding: 0 var(--page-inset);
  min-height: 25svh;

  &.clean {
    padding: 0;
    border: 0;
  }
}
