.modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999; /* Needs to compete with external iframes from GTM */
  height: 100vh;
  height: 100dvh;
  width: min(520px, 100vw);

  padding: 16px 0;
  transform: translateX(100%);
  transition: all 300ms;
  background: var(--color-quaternary);

  @media (--small) {
    padding: 0 0 var(--spacing-48);
  }

  & input {
    background: transparent;
  }

  & .modalInner {
  }

  &.cart {
    @media (--small) {
      padding: 0 0 var(--page-inset);
    }
    & .modalInner {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    & .title {
      @extend %typo-body2;
    }
  }
}

.modal__container {
  display: flex;
  flex-direction: column;
  gap: var(--section-spacing);
  width: 100%;
  /* scroll */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 100%;
}

.title {
  @extend %typo-headline3;
}

.open {
  transform: translateX(0%);
}

.modal__toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-8) calc(var(--page-inset) - 18px) var(--spacing-24)
    var(--page-inset);

  --svg-color: var(--color-white);

  &_actions {
    display: flex;
    align-items: center;

    & img {
      width: 24px !important;
    }
  }
}

.footer {
  & .modalInner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
