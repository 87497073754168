:root {
  /* Spacing sets */

  --base: 8px;

  --spacing-4: calc(var(--base) / 2);
  --spacing-8: var(--base);
  --spacing-8-variable: var(--spacing-8);
  --spacing-12: calc(var(--base) * 1.5);
  --spacing-12-variable: var(--spacing-12);
  --spacing-16: calc(var(--base) * 2);
  --spacing-16-variable: var(--spacing-16);
  --spacing-20: calc(var(--base) * 2.5);
  --spacing-20-variable: var(--spacing-20);
  --spacing-24: calc(var(--base) * 3);
  --spacing-24-variable: var(--spacing-24);
  --spacing-32: calc(var(--base) * 4);
  --spacing-32-variable: var(--spacing-32);
  --spacing-40: calc(var(--base) * 5);
  --spacing-40-variable: var(--spacing-40);
  --spacing-48: calc(var(--base) * 6);
  --spacing-48-variable: var(--spacing-48);
  --spacing-64: calc(var(--base) * 8);
  --spacing-96: calc(var(--base) * 12);
  --spacing-128: calc(var(--base) * 16);

  --base-spacing: var(--base);
  /* vertical distance between blocks and sections */
  --section-spacing: var(--spacing-48);
  --margin-spacing: var(--spacing-20-variable);
  /* used for gaps between columns in flex or grid containers */
  --column-gap: var(--spacing-16);
  --row-gap: var(--spacing-64);
  --paragraph-width: 700px;

  @media (--small) {
    --spacing-8-variable: var(--spacing-12);
    --spacing-12-variable: var(--spacing-16);
    --spacing-20-variable: var(--spacing-24);
    --spacing-24-variable: var(--spacing-32);
    --spacing-32-variable: var(--spacing-40);
    --spacing-40-variable: var(--spacing-48);
    --spacing-48-variable: var(--spacing-64);
  }
}
