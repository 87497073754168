.accordion-group {
  margin: var(--spacing-24) 0;
  @extend %typo-body2;
  border-top: 1px solid var(--color-divider);

  & h3 {
    @extend %typo-subtitle2;
    text-transform: uppercase;
    margin-bottom: var(--spacing-8);
  }

  & p + h3 {
    margin-top: var(--spacing-24);
  }

  & p + p {
    margin-top: 1rem;
  }

  & p a {
    color: var(--color-primary);
  }
}

.productfaq {
  margin-bottom: var(--spacing-16);

  & p {
    @extend %typo-body2;
    white-space: pre-line;
  }
}

.table {
  @extend %typo-body2;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--spacing-24);
  & th {
    text-align: left;
    padding-right: var(--spacing-16);
    font-weight: normal;
    color: #0e0e0e;
    @extend %typo-body2;
  }

  & td {
    text-align: right;
  }

  & td,
  & th {
    padding-block: var(--spacing-4);
  }
  /*
  & tr:nth-child(odd) {
    background-color: var(--color-tertiary);
  }
  */
}

.text {
  padding-left: 0px !important;
  max-width: 100% !important;
}

.bundleSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (--large) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
