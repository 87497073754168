.root {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-8);

  @media (--small) {
    display: block;
    margin-top: var(--spacing-20);
  }
}

.modal {
  color: var(--color-dark);

  &__content {
    padding: var(--page-inset);
  }
}

.listItem {
  cursor: pointer;
  margin: var(--spacing-8) 0;

  &:hover {
    text-decoration: underline;
  }

  &__selected {
    font-weight: bold;
  }
}

.label {
  @extend %typo-caption1;
  text-transform: uppercase;
}

.section {
  margin-bottom: var(--spacing-24);
  padding-bottom: var(--spacing-24);
}

.langButton,
.langButton:hover:not(:disabled) {
  color: var(--color-white) !important;

  &::before,
  &::after {
    background: var(--color-white) !important;
  }
}
