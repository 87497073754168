/* 
  Let's use the Page and Section components instead of these classes in this project
  - storing these for overview and initial setup
 */

html,
body {
  height: 100%;
}

:root {
  --page-width: 1680px;
  --section-width: 100%;
  --content-width: 100%;

  --section-margin: calc(var(--section-spacing) * 2);
  --content-margin: var(--margin-spacing);
  --stacked-margin: var(--margin-spacing);

  --page-inset: var(--margin-spacing); /* General site horizontal margins */
  --smart-page-inset: var(--page-inset);

  --page-inset-width: min(
    var(--page-width),
    calc(100vw - (var(--page-inset) * 2))
  );
  --section-inset-width: min(
    var(--section-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --content-inset-width: min(
    var(--content-width),
    calc(100% - (var(--page-inset) * 2))
  );
  @media (--small) {
    --smart-page-inset: max(
      calc((100vw - var(--page-width)) / 2 - 8px),
      calc(var(--page-inset) / 2)
    );
  }

  --column: calc(100% / 12);
}

[data-layout="page"] {
  max-width: var(--page-inset-width);
  margin: 0 auto;

  & > [data-layout="section"] {
    max-width: var(--section-width);
  }

  .modal & {
    max-width: 100%;
  }
}

[data-blok] + [data-blok] {
  margin-top: var(--section-margin);
}

.navigation-bar + [data-blok]:not([data-blok-background][data-layout="full"]) {
  margin-top: var(--section-margin);
}

.page-wrapper {
  padding-top: var(--header-height);

  height: 100%;
  display: flex;
  flex-direction: column;

  &:has(#snackbarOuter) {
    padding-top: calc(var(--header-height) + var(--snackbar-height));
    & .hero {
      --hero-max-height: calc(
        100vh - var(--header-height) - var(--snackbar-height)
      );
    }
  }

  &:has(.pdp-page) {
    padding-top: 0;
  }
}

.page-blok {
  flex: 1;
}

.inset {
  padding-left: var(--page-inset);
  padding-right: var(--page-inset);
}

img {
  max-width: 100%;
  height: auto;
}

.divider {
  border-bottom: 1px solid var(--color-divider);
}
