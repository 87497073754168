.inner {
  padding: var(--page-inset);

  & form {
    margin: var(--spacing-24) 0;
    display: flex;
    gap: var(--spacing-8);
  }

  & input {
    border: 0;
    border-bottom: 1px solid var(--color-divider);
    color: var(--color-dark);
    font-family: var(--font-beausite);
    font-size: var(--body1-size);
    font-weight: 300;
    height: 48px;
    letter-spacing: 0;
    line-height: 1.5;
    width: 100%;
  }
}

.inputs {
  width: 100%;
}
