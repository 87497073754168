* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: var(--color-background);

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    transition:
      background 300ms,
      width 0ms 150ms,
      height 0ms 150ms;
    background: rgba(0, 0, 0, 0);
  }

  &.scroll-locked {
    overflow: hidden;

    &::after {
      z-index: 888;
      width: 100%;
      height: 100%;
      transition: background 300ms;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

/* 
 * .sr-only
 * 
 * DEPRECATED: 
 * use the Hidden component instead - leaving this here for backwards compatibility
 *
 * @description:
 * good way to make something accesible to screen readers, but not visible 
*/

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.desktop {
  @media (--mobile-only) {
    display: none;
  }
}

.mobile {
  @media (--small) {
    display: none;
  }
}

/* adyen */
.adyen-drop-in-container {
  & .adyen-checkout__payment-method {
    border: 0;
  }

  & .adyen-checkout__payment-method--selected {
    border: 0;
    background: transparent;
  }

  & .adyen-checkout__button {
    background: var(--color-primary);

    &:hover {
      background: var(--color-primary-dark);
    }
  }

  & .adyen-checkout__payment-method__radio--selected {
    background: var(--color-primary);
  }

  & .adyen-checkout__payment-method__radio {
    left: 0;
  }

  & .adyen-checkout__payment-method__details {
    padding: 0;
  }

  & .adyen-checkout__payment-method__header {
    padding-left: 32px;
  }

  & .adyen-checkout__button__text {
    font-family: var(--font-beausite);
  }
}
