.form {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: var(--spacing-16);

  & .inputWrap {
    width: 100%;
  }

  & > *:first-child {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.wrap {
  width: 100%;
}

.error {
  color: var(--color-error);
  margin-top: var(--spacing-8);
}

.subContainer {
  display: flex;
  align-items: center;
  line-height: 1;
  & p {
    line-height: 1;
  }
}

.toggle {
  padding: 7px;
  & svg {
    transform: none;
  }
}
