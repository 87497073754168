@import "swiper/css";
@import "swiper/css/navigation";

body {
  /* body to increase specificity */
  & .swiper-button-prev,
  & .swiper-button-next {
    background-color: var(--color-tertiary);
    border-radius: 100%;
    width: 40px;
    height: 40px;
    --swiper-navigation-size: 14px;
    color: var(--color-primary);
    opacity: 0;
    transition: opacity 250ms;

    &:hover {
      background: var(--color-white);
    }
  }

  & .swiper:hover .swiper-button-prev,
  & .swiper:hover .swiper-button-next {
    opacity: 1;
  }

  & .swiper-button-next.swiper-button-disabled,
  & .swiper-button-prev.swiper-button-disabled,
  & .swiper:hover .swiper-button-next.swiper-button-disabled,
  & .swiper:hover .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }

  & .swiper-pagination-bullets {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    gap: 6px;
    justify-content: center;
    padding: 27px;
  }

  & .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: var(--color-primary);
    display: block;
    opacity: 0.3;
    transition: all 200ms linear;

    &.swiper-pagination-bullet-active {
      opacity: 1;
      width: 12px;
    }
  }
}
