.cart-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
}

.cart-empty {
  padding: var(--page-inset);

  & h3 {
    margin-top: var(--spacing-16);
  }
}

.cart__indicator {
  background: var(--color-primary);
  color: var(--color-white);
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: absolute;
  line-height: 16px;
  font-size: 11px;
  top: 10px;
  right: 6px;
}
