.modal {
  --modal-max-width: 400px;
  --spacing-28: 28px;
  --container-padding: var(--spacing-16) var(--spacing-24) var(--spacing-28)
    var(--spacing-24);
  z-index: 920;
  height: fit-content;
  max-width: var(--modal-max-width);
  align-items: center;
  min-height: var(--modal-height);
  animation: fadeIn 0.6s;
  background-color: var(--color-primary);
  position: fixed;
  left: var(--spacing-8);
  right: var(--spacing-8);
  bottom: var(--spacing-8);
  display: flex;
  flex-direction: column;
  padding: var(--container-padding);
  overflow: auto;
  border-radius: 4px;

  @media (--large) {
    left: var(--spacing-16);
    right: 0;
  }

  /* START Target fields inside Klaviyo-form to style it here */
  & [data-testid~="form-component"] {
    & label {
      font-weight: 400 !important;
      gap: var(--spacing-8);
      align-items: flex-start;

      & svg {
        align-self: baseline !important;
        margin-top: 3px !important;
      }

      & div {
        margin-right: 0 !important;
      }
    }
    & p {
      margin-left: var(--spacing-8) !important;
      padding-bottom: 6px !important;
    }
    & input {
      border: none !important;
      border-bottom: 1px solid var(--divider, rgba(255, 255, 255, 0.16)) !important;
    }

    & button {
      font-size: var(--font-size-s) !important;
    }
  }

  & [component~="[object Object]"] {
  }
}
/* END Target fields inside Klaviyo-form to style it here */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.submit {
  width: 100%;
}

.text-center {
  text-align: center;
}
.close {
  align-self: end;
  border: none;
  background: transparent;
  color: var(--color-white);

  &:hover {
    cursor: pointer;
  }
}

.title {
  @extend %typo-headline3;
}

.intro.intro {
  --line-break: 15px;
  --margin-top: 46px;
  @extend %typo-body2;
  padding-right: var(--spacing-8);
  margin-top: var(--margin-top);
  color: var(--color-all-white);

  & > span {
    margin-top: var(--line-break);
  }

  & a {
    color: var(--color-all-white);
    text-decoration: underline;
    display: inline;

    &:hover {
      text-decoration: none;
    }
  }
}

.modal-button-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 0 5px;
  border-radius: 50px;
}
.policyText {
  font-size: var(--font-size-2xs);
  margin-top: 24px;
  @media (--large) {
    margin-top: 40px;
  }
}
.inputgroup {
  --input-gap: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--input-gap);
  @media (--small) {
    flex-wrap: unset;
  }
}
.formgroup {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  @media (--large) {
    margin-top: 50px;
  }
}
.ingress,
.formgroup {
  width: 100%;
  @media (--small) {
    width: 85%;
  }
  & input,
  button {
    border: 1px solid transparent;
  }
}
.success {
  --text-spacing: 80px;
  margin-block: var(--text-spacing);
}
.errorMessage {
  color: red;
}
.input {
  --transition: 500ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 17px;
  --input-padding-h: 11px;
  --input-br: 100px;
  @media (--large) {
    --input-font-size: var(--font-size-2xs);
    --input-padding-v: 1.5em;
    --input-padding-h: 1em;
  }
  width: 100%;
  padding-inline: var(--input-padding-v);
  padding-block: var(--input-padding-h);
  font-size: var(--input-font-size);
  background: var(--color-primary-20);
  border-radius: var(--input-br);
  border: 0;

  &:focus {
    border: 1px solid var(--color-primary);
  }
  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-primary-70);
    font-size: var(--input-font-size);
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
    }
  }
}
