:root {
  --snackbar-height: 36px;
  --header-offset-mobile: 10px;
  --header-height-scroll: 48px;
  --header-height: 72px;
  --header-transition-speed: 250ms;

  @media (--large) {
    --header-height: 72px;
  }
}
