/* Font scale */

/*
*** DO NOT USE THESE VARIABLES IN CSS FILES!
** These are used to create tokens in ./tokens.css
** Always extend tokens when applying styles to text
*/

:root {
  /* Font size litterals */
  --font-size-rest1: 13px;

  --font-size-2xs: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 22px;
  --font-size-3xl: 24px;
  --font-size-4xl: 26px;
  --font-size-5xl: 28px;
  --font-size-6xl: 32px;
  --font-size-7xl: 40px;
  --font-size-8xl: 48px;
  --font-size-9xl: 56px;
  --font-size-10xl: 64px;

  /* dynamic sizes - comment out to disable 
  --font-size-rest1: clamp(13px, calc(13 / 1200 * 100vw), 100vw);

  --font-size-2xs: clamp(11px, calc(11 / 1200 * 100vw), 100vw);
  --font-size-xs: clamp(12px, calc(12 / 1200 * 100vw), 100vw);
  --font-size-s: clamp(14px, calc(14 / 1200 * 100vw), 100vw);
  --font-size-m: clamp(16px, calc(16 / 1200 * 100vw), 100vw);
  --font-size-l: clamp(18px, calc(18 / 1200 * 100vw), 100vw);
  --font-size-xl: clamp(20px, calc(20 / 1200 * 100vw), 100vw);
  --font-size-2xl: clamp(22px, calc(22 / 1200 * 100vw), 100vw);
  --font-size-3xl: clamp(24px, calc(24 / 1200 * 100vw), 100vw);
  --font-size-4xl: clamp(26px, calc(26 / 1200 * 100vw), 100vw);
  --font-size-5xl: clamp(28px, calc(28 / 1200 * 100vw), 100vw);
  --font-size-6xl: clamp(32px, calc(32 / 1200 * 100vw), 100vw);
  --font-size-7xl: clamp(40px, calc(40 / 1200 * 100vw), 100vw);
  --font-size-8xl: clamp(48px, calc(48 / 1200 * 100vw), 100vw);
  --font-size-9xl: clamp(56px, calc(56 / 1200 * 100vw), 100vw);
  ------------- */

  /* Font token variables */
  --headline1-size: var(--font-size-6xl);
  --headline2-size: var(--font-size-2xl);
  --headline3-size: var(--font-size-l);
  --subtitle1-size: var(--font-size-m);
  --subtitle2-size: var(--font-size-s);
  --caption1-size: var(--font-size-rest1);
  --caption2-size: var(--font-size-2xs);

  --body1-size: var(--font-size-m);
  --body2-size: var(--font-size-s);
  --body3-size: var(--font-size-xs);

  --button-size: var(--font-size-s);
  --label-size: var(--font-size-2xs);

  @media (--small) {
    --headline1-size: var(--font-size-7xl);
    --headline2-size: var(--font-size-3xl);
  }

  @media (--large) {
    --headline1-size: var(--font-size-8xl);
    --headline2-size: var(--font-size-6xl);
    --headline3-size: var(--font-size-xl);
  }

  @media (--xlarge) {
    --headline1-size: var(--font-size-10xl);
    --headline2-size: var(--font-size-7xl);
  }
}

body {
  @extend %typo-body1;
  color: var(--color-dark);
  z-index: 0;
  font-feature-settings: "kern", "liga";
}

button {
  color: var(--color-dark);
}

p,
h1,
h2,
h3 {
  margin: 0;
}

.caption,
.preamble {
  text-transform: uppercase;
  @extend %typo-caption1;
}

h1,
.h1,
.heading1 {
  @extend %typo-headline1;
}

h2,
.h2,
.heading2 {
  @extend %typo-headline2;
}

h3,
.h3,
.heading3 {
  @extend %typo-headline3;
}
