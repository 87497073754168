.wrapper {
  display: flex;
  gap: var(--spacing-12);
  align-items: center;
  flex-wrap: wrap;
}

.googleRating {
  display: flex;
  margin-bottom: var(--spacing-16);
  align-items: center;
  gap: var(--spacing-8);
  color: var(--color-primary-60);
}

.link {
  @extend %typo-caption2;
  color: var(--text-primary);
  text-decoration: none;
}

.link:visited {
  color: inherit;
}
