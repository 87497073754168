/* Block context specifc css */
.plp__header .hero {
  border-bottom: 1px solid var(--color-divider);

  & img {
    aspect-ratio: 3 / 2;
    
    @media (--small) {
      aspect-ratio: unset;
    }
  }

  @media (--small) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    --hero-max-height: calc(
      100vh - var(--header-height) - 100px
    ); /* show filters */
    --hero-image-max-height: var(--hero-max-height);

    & > * {
      width: 50%;
    }
  }

  & .hero-content {
    padding: var(--section-spacing) var(--page-inset);

    @media (--small) {
      text-align: left;
      padding-top: 0;
      padding: var(--section-spacing) var(--page-inset) var(--section-spacing) 0;
    }

    & h1,
    & h2 {
      margin: 0 0 var(--spacing-8);

      @media (--small) {
        text-align: left;
      }
    }

    & p,
    & .richText {
      text-align: center;
      margin: 0;
      @extend %typo-body2;

      @media (--small) {
        text-align: left;
        max-width: 520px;
      }
    }

    & p {
      opacity: 0.72;
    }
  }

  &[data-layout="full"] .hero-content {
    @media (--small) {
      padding-left: var(--smart-page-inset);
    }
  }
}

.navigation-bar:has(.active),
.navigation-bar:has(.menu-section:hover) {
  background: var(--color-primary);
  color: var(--color-primary-contrast);

  & a {
    color: var(--color-primary-contrast);
  }

  & svg path {
    --svg-color: var(--color-primary-contrast);
    fill: var(--color-primary-contrast);
  }

  /* if necessary
  & button:hover {
    background-color: var(--color-primary-dark) !important;
  }
  */
}
