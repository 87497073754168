.title {
  @extend %typo-headline3;
  text-transform: none;
  display: block;
  
}

.text {
  @extend %typo-body2;
  text-transform: initial;
  display: block;
}

.title__container {
  @extend %typo-body2;
  margin-bottom: var(--spacing-64);
}

.container {
  @media (--small) {
    display: flex;

    & > * {
      width: 50%;
    }
  }
}

.info {
  padding: var(--spacing-20) var(--page-inset);

  @media (--small) {
    padding: calc(96px + var(--header-height)) var(--margin-spacing);
  }

  @media (--pdplarge) {
    padding: calc(96px + var(--header-height)) 4vw;
  }
}

.price {
  margin-top: var(--spacing-4);
  display: block;
}

.info__container {
  max-width: 640px;
}

.gallery {
  background: var(--color-tertiary);
  aspect-ratio: 3 / 4;

  @media (--small) {
    max-height: 100vh;
    position: sticky;
    top: 0;
  }
}

.lead {
  @extend %typo-body1;
  margin: var(--spacing-24) 0;
}

.usps {
  padding-left: var(--spacing-32);
}

.features {
  @extend %typo-caption2;
  text-transform: uppercase;
  margin: var(--spacing-24) 0;
  background: var(--color-tertiary);
  padding: var(--spacing-24) var(--spacing-16);
  border-radius: 4px;
  gap: var(--spacing-16);
  display: flex;

  &__featurewrap {
    display: flex;
    gap: var(--spacing-16);
    width: 100%;
    &::after {
      height: 51px;
      width: 1px;
      display: block;
      content: "";
      background-color: var(--color-divider);
    }

    &:last-child::after {
      content: none;
    }
  }

  &__feature {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-12);
    text-align: center;
    justify-content: center;

    & img {
      width: 34px;
      height: 34px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
  }
}

.tags {
  display: flex;
  gap: var(--spacing-8);
  margin-top: var(--spacing-16);
}

.tag {
  background: var(--color-tertiary);
  border-radius: 2px;
  padding: 4px 6px;
  text-transform: uppercase;

  @extend %typo-caption-product-card;

  &__dark {
    background: var(--color-primary);
    color: var(--color-light);
  }
}

.story {
  margin-top: var(--section-margin);
}

.footerHack {
  @media (--small) {
    margin-bottom: calc(0px - var(--section-spacing) * 2);
  }
}
