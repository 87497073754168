.base {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  gap: var(--gap);
  justify-content: center;
  place-items: center;
  position: relative;
  transition: all 0.2s ease;
  padding: 6px;
}

.dark {
  color: var(--color-primary);
  text-decoration-color: var(--color-primary);
}

.light {
  color: var(--color-white);
  text-decoration-color: var(--color-white);
}

.underlined {
  font-weight: var(--grot-medium);
  letter-spacing: 1.6px;
  line-height: calc(8 / 12);
  font-size: var(--font-size-5xs);
  font-weight: var(--grot-roman);
  text-transform: uppercase;

  & > span {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      top: auto;
      bottom: -6px;
      margin-inline: auto;
      width: 100%;
      height: 1px;
      background-color: var(--color-primary);
      transition: all 150ms ease;
    }
  }
  &:hover {
    & > span {
      &::after {
        width: 0%;
      }
    }
  }
}

.small {
  font-size: var(--font-size-3xs);
  line-height: calc(11 / 13);
  letter-spacing: 0.44px;
  font-weight: var(--grot-roman);
  text-decoration: none;
}

.medium {
  font-size: var(--font-size-3xs);
  line-height: calc(11 / 13);
  letter-spacing: 0.44px;
  font-weight: var(--grot-roman);
  text-decoration: none;
  text-transform: uppercase;
}

.large {
  font-size: var(--font-size-s);
  line-height: calc(16 / 23);
  letter-spacing: 0.48px;
  font-weight: var(--grot-roman);
  text-decoration: none;
}
