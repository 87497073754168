.zoomImageContainer {
  /* reset */
  all: unset;

  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  background-size: 150%;
  --svg-color: black;

  & .image {
    display: block;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.zoom {
    cursor: crosshair;
    & .image {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.zoomButton {
  appearance: none;
  position: absolute;
  bottom: var(--spacing-20);
  right: var(--spacing-20);
  z-index: 10;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  padding: var(--spacing-8);
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  --svg-color: black;
  display: flex;
  @media (hover: hover) {
    &:hover {
      background-color: revert;
    }
  }

  & svg {
    width: var(--spacing-20);
    height: var(--spacing-20);
  }
}

.zoomContainer {
  position: fixed;
  overflow: hidden;
  z-index: 999;
  inset: 0;
  background-color: var(--color-white);

  & .viewport {
    width: 100%;
    height: 100%;
  }

  & .content {
    width: 100%;
    height: 100%;
    position: relative;
  }

  & .zoomedImage {
    object-fit: contain;
    z-index: -1;
    cursor: move;
  }

  & .zoomClose {
    position: absolute;
    top: var(--spacing-20);
    right: var(--spacing-20);
    bottom: revert;
    --svg-color: black;
  }

  & .message {
    --message-bottom: 24px;
    position: absolute;
    bottom: var(--message-bottom);
    left: 50%;
    transform: translateX(-50%);
    @extend %typo-body2;
    color: var(--color-tertiary-text);
  }
}
