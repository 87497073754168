.features {
  --border-radius: 4px;
  --text-size: 9px;

  @extend %typo-caption2;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: var(--text-size);
  background: var(--color-tertiary);
  padding: var(--spacing-24) 0;
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-20);

  &__featurewrap {
    display: flex;

    &:not(:last-child) {
      border-right: 1px solid var(--color-divider);
    }
  }

  &__feature {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-4);
    text-align: center;
    justify-content: center;
    padding-inline: var(--spacing-12);

    & img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
  }
}
