:root {
  /* ------- Base colors ------- */
  --color-all-white: #fff;
  --color-white: #fcfdfc;
  --color-black: #0e0e0e;
  --color-dark: var(--color-black);
  --color-light: var(--color-white);
  --color-base: var(--color-white);

  /* TODO: greyscale */

  /* Primary - Provides ways to accent and distinguish your product.  */
  --color-primary: #144733;
  --color-primary-light: #436c5c;
  --color-primary-dark: #103929;
  --color-primary-contrast: var(--color-white);

  /* Secondary - Use only large or bold text for Light backgrounds (AA pass large text). */
  --color-secondary: #4f7f70;
  --color-secondary-light: #72998d;
  --color-secondary-dark: #3f665a;
  --color-secondary-contrast: var(--color-white);
  --color-secondary-text: rgba(14, 14, 14, 0.72);

  /* Tertiary */
  --color-tertiary: #f2f3f1;
  --color-tertiary-light: #fafaf9;
  --color-tertiary-dark: #c2c2c1;
  --color-tertiary-text: rgba(14, 14, 14, 0.64);
  --color-tertiary-contrast: var(--color-black);

  /* Quaternary */
  --color-quaternary: #f1eee9;
  --color-quaternary-light: #d5cbbc;
  --color-quaternary-dark: #b9a98f;
  --color-quaternary-contrast: var(--color-black);

  /* Suplementary */
  --color-info: #00bcd4;
  --color-success: #4caf50;
  --color-warning: #ff9800;
  --color-error: #f44336;
  --color-divider: rgba(14, 14, 14, 0.16);
  --color-divider-contrast: rgba(255, 255, 255, 0.16);
  --color-disabled-background: rgba(0, 0, 0, 0.12);

  /* swatches */
  --color-picker-red: #d03c3c;
  --color-picker-orange: #e6e3dc;
  --color-picker-yellow: #f0d874;
  --color-picker-green: #81ad58;
  --color-picker-blue: #679abf;
  --color-picker-pink: #efbecf;
  --color-picker-purple: #b99edd;
  --color-picker-white: var(--color-white);
  --color-picker-grey: #bcb8af;
  --color-picker-beige: #d9caa2;
  --color-picker-brown: #7e5430;
  --color-picker-black: var(--color-black);
  --color-picker-navy: #123258;
  --color-picker-khaki: #9d9d71;

  /* text */
  --color-text: rgba(14, 14, 14, 1);
  --color-text-secondary: rgba(14, 14, 14, 0.72);
  --color-text-disabled: rgba(0, 0, 0, 0.28);
}
